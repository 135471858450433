// Branding
$header-font: 'Playfair Display', serif;
$body-font: 'proxima-nova', sans-serif;
$caps-font: 'Poiret One', sans-serif;
$grey: #656565;
$black: #000000;
$white: #fff;
$dark-grey: #414141;
$light-grey: #eee;
$purple: #5b657f;
$divider: 1px solid rgba($color: #000000, $alpha: .2);
