.home-slider {
    padding: 0;
    .slide {
        overflow-x: hidden;
    }
    .container-fluid {
        margin: 0;
    }
    .row {
        height: 500px;
    }
    .col-md-6 {
        height: 500px;
    }
    .slide-1 {
        height: 100%;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .flex-center-col {
        align-items: center;
    }
    p {
        max-width: 700px;
    }
    .slick-dots li button::before {
        font-size: 15px;
    }
    .slick-dots li.slick-active button::before {
        color: $grey;
    }
    .slick-dots {
        bottom: -50px;
    }
    .grey-bg {
        background-color: $light-grey;
    }
}

.overview {
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        p {
            max-width: 700px;
        }
    }
}

.featured-video-slider {
    .btn {
        max-width: 210px;
    }
    .arrows {
        height: 25px;
        width: 60px;
        margin: 25px 0;
        .prev {
            float: left;
            img {
                height: 25px;
                width: 15px;
                &:hover {
                    cursor: pointer;
                }
            }
            
        }
        .next {
            float: right;
            img {
                height: 25px;
                width: 15px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.trend-img {
    height: 250px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.events {
    .event {
        .event-img {
          height: 400px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          margin: 2.5px 0;
          a {
            background-color: rgba(0,0,0,0.2);
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            h1 {
                width: 80%;
            }
          }
        }
    }
}

.text-image-right {
    .container {
    .row {
            .btn {
                width: 180px;
            }
        }
        h4 {
            text-align: left;
            padding: 1em 0 1.5em 0;
        }
        .text-image {
            padding-right: 30px;
        }
    }
}

.text-image-wrap {
        background-position: center;
        background-size: cover;
    @media (max-width: 767px) {
        display: none;
    }
}

.text-image-left {
    .container {
    .row {
            .btn {
                width: 180px;
            }
        }
        h4 {
            text-align: left;
            padding: 1em 0 1.5em 0;
        }
        .text-image {
            padding-left: 30px;
        }
    }
}

.newsletter {
    background-color: $light-grey;
}