.img-wrap {
    height: 500px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.shop-featured-image {
    padding-top: 0;
    .jumbotron-fluid {
        height: 500px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @media (max-width: 330px) {
            height: 600px;
        }
        .shop-details {
            background-color: rgba($color: #ffffff, $alpha: .7);
            margin-left: auto;
            margin-top: auto;
            padding: 30px 60px;
            @media (max-width: 330px) {
                padding: 10px 20px;
            }
            a {
                color: $black;
            }
            .view-photos {
                a {
                    display: flex;
                    flex-direction: row;
                    &:nth-child(n+2) {
                        display: none;
                    }
                }
                img {
                    margin-bottom: 1rem;
                    margin-left: 1rem;
                }
            }
            ul {
                padding-left: 0;
                list-style: none;
            }
            h4 {
                padding-bottom: 0;
                text-align: left;
            }
        }
    }
}

.info {
    h4 {
        text-align: left;
    }
    .shop-logo {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
    .shop-redactor {
        border-left: $divider;
        padding-left: 40px;
        @media (max-width: 768px) {
            border-left: none;
            padding-left: 20px;
        }
    }
}

.shop-map {
    img {
        width: 100%;
    }
}

.social {
    .container {
        padding: 70px 0;
        img {
            height: 50px;
            width: auto;
        }
        .social-footer {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        a {
            padding-left: 50px;
        }
    }
}
.interior-header {
    padding-top: 0;
    .jumbotron-fluid {
        p {
            color: white;
            text-align: center;
        }
        h1 {
            color: white;
        }
    }
}

.info {
    .col-md-4 {
        @media (max-width: 768px) {
            padding-bottom: 30px;
        }
    }
}