.events {
    .container {
        .row {
            img {
                width: 100%;
            }
        }
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            h1 {
                position: absolute;
                text-transform: uppercase;
                color: $white;
                margin: 0;
            }
        }
    }
}

.divider {
    padding-top: 50px;
    padding-bottom: 50px;
    border-bottom: $divider;
}

.date {
    position: absolute;
    top: 15px;
    right: 30px;
    background-color: $white;
    padding: .7em 1.5em;
    h3 {
        padding: 0;
    }
}
.date-flex {
    display: flex;
    justify-content: center;
}

@media (max-width: 768px) {
    .date-flex {
        justify-content: left;
    }
}

.date-event {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #FAFAFA;
    padding: 10px 10px;
    width: 100px;
    height: 100px;
    margin-top: 2em;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    h3 {
        padding: 0;
    }
}

.event-info {
    text-align: left;
    h4 {
        text-align: left;
        padding-bottom: 10px;
    }
}