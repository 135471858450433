.featured-callout {
    .container {
        .row {
            .img-wrap {
                height: 500px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                background-attachment: fixed;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                img {
                    width: 100%;
                    //height: 100%;
                }
                h1 {
                    text-align: center;
                    max-width: 80%;
                    line-height: 1.5em;
                    color: white;
                    span {
                        background-image: linear-gradient(to right, #000 0%, #000 100%);
                        background-repeat: repeat-x; // make it horizontal
                        background-position: 0 95%; // change the second value to adjust how far from the top the underline should be
                        background-size: 100% 100%; // change the second value to the desired height of the underline
                }
            }
        }
    }
}
}