#footer {
    background-color: black;
    color: white;
    .container {
        padding: 70px 0;
    }
    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        list-style: none;
        padding-left: 0;
        li {
            padding: 10px 25px;
        }
    }
    .social-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin: 40px 0;
        img {
            height: 27px;
            padding: 0 25px;
        }
    }
    a {
        color: white;
    }
}