h1 {
    font-family: $header-font;
    margin-bottom: 30px;
    color: $black;
    font-size: 2rem;
}

h3 {
    text-transform: uppercase;
    color: $grey;
    font-family: $header-font;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    padding: 2em 0;
}

h4 {
    text-transform: uppercase;
    color: $grey;
    font-family: $body-font;
    padding: 2em 0;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
}

p {
    font-family: $body-font;
    color: $black;
}

a {
    font-family: $body-font;
}
a:hover {
    text-decoration: none;
}