main {
    overflow: hidden;
}

section {
    padding: 3em 0;
}

.flex-center-col {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.img-fluid {
    img {
        width: 100%;
    }
}

// Form stuff
.form-group {
    text-align: left;
}
.btn-primary {
    border: none;
    margin-top: 0;
}
.form-control {
    background-color: #E6E6E6;
    border: 1px solid #B7B7B7;
    border-radius: 0px;
}
nav {
    z-index: 10000;
}

.overlay {
    z-index: 1;
    background-color: rgba($color: #000000, $alpha: .5);
    position: absolute;
    width: 100%;
    height: 500px;
}

.inner {
    position: relative;
    z-index: 2;
}

.slick-prev:before, .slick-next:before{
    color: black;
}

.btn {
    border-top: 1px solid transparent;
    color: white;
    transition: all .3s; 
    &:hover {
        border: 1px solid black;
        background-color: transparent;
        color: black;
    }
}

@media (max-width: 767px) {
    .navbar li {
        padding-right: 0;
    }
    .nav-item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        border-bottom: $divider;
        &:nth-child(1) {
            padding-top: 30px;
        }
    }
}