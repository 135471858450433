.shop-grid {
    padding-top: 75px;
    padding-bottom: 100px;
    .container {
        text-align: center;
        color: $dark-grey;
        h1 {
            padding-bottom: 10px;
        }
        .row {
            .shop-outer {
                margin-top: 80px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .shop-inner {
                width: 300px;
                height: 300px;
                box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                @include media-breakpoint-down(md) {
                    width: 200px;
                    height: 200px;
                }
                @include media-breakpoint-down(sm) {
                    width: 300px;
                    height: 300px;
                }
            }
        }
    }
}