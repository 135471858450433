.trend {
    padding-top: 0;
    .jumbotron-fluid {
        h1 {
            color: white;
        }
        h4 {
            color: white;
            padding-bottom: 10px;
        }
    }
}

.post-content {
    .post-content-block {
        padding: 20px 0;
    }
    .img-fluid {
        padding: 20px 0;
    }
    h1 {
        padding-top: 20px;
    }
    .blockquote {
        h1 {
            padding: 30px 0;
            text-align: center;
            font-size: 30px;
            line-height: 2;
        }
    }
}

.trend-wrapper {
    h1 {
        text-align: center;
    }
    p {
        text-align: center;
    }
    h4 {
        padding-bottom: 10px;
    }
}

.trend-list {
    .col-md-4 {
        @media (max-width: 768px) {
            padding-bottom: 30px;
        }
    }
}