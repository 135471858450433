
h1 {
    font-family: $header-font;
}

p {
    font-family: $body-font;
}

a {
    font-family: $body-font;
}

.navigation {
    .logo-wrap {
        height: 100px;
        width: 100vw;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 400px;
            height: auto;
            @include media-breakpoint-down(sm) {
                width: 300px;
            }
        }
    }
}

.coming-soon {
    .container-fluid {
        h1 {
            // margin-top: 200px;
            padding-bottom: .5em;
            color: white;
            font-size: 2rem;
            @include media-breakpoint-down(sm) {
                margin-top: 0px;
            }
        }
        p {
            color: white;
            font-weight: lighter;
            font-size: .9rem;
            padding-bottom: 1rem;
        }
        a {
            color: black;
            font-weight: lighter;
            color: white;
            font-size: 1rem;
        }
        .row {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $purple;
            color: white;
            .img-bg {
                background: url("/assets/images/galleria-sign-5.jpg") no-repeat center/cover;
                height: 500px;
            }
            .col-md-6 {
                padding: 0 100px;
                @include media-breakpoint-down(sm) {
                    padding: 0 15px;
                }
                img {
                    height: 50px;
                    color: white;
                    margin-bottom: 30px;
                }
            }
        }
    }
}
.shop-grid {
    padding-top: 75px;
    padding-bottom: 100px;
    .container {
        text-align: center;
        color: $dark-grey;
        h1 {
            padding-bottom: 10px;
        }
        .row {
                .shop-outer {
                    margin-top: 80px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                .shop-inner {
                    width: 300px;
                    height: 300px;
                    box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                    @include media-breakpoint-down(md) {
                        width: 200px;
                        height: 200px;
                    }
                    @include media-breakpoint-down(sm) {
                        width: 300px;
                        height: 300px;
                    }
            }
        }
    }
}