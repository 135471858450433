.navbar {
    overflow-x: hidden;
    box-shadow: 0 1px 30px rgba(0,0,0,0.01),0 1px 20px rgba(0,0,0,.05);
    background-color: white;
    @media (max-width: 767px) {
        display: flex;
        justify-content: center;
        align-items: center;
        }
    li {
        padding-right: 30px;
        @media (max-width: 767px) {
            padding-right: 0;
            }
        &:last-child {
            border-bottom: none;
        }
    }
    img {   
        width: 320px;
        @media (max-width: 767px) {
            padding-bottom: 10px;
            }
     }
    a {
        color: rgba($color: #000000, $alpha: .5)
    }
    #mobileToggle {
        color: white;
        border-color: rgba(0,0,0,0);
    }
}

@media (max-width: 767px) {
    .navbar {
        img {
            width: 100%;
        }
    }
}